import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';
import Subscribe from './components/Subscribe';
import Dashboard from './components/Dashboard';
import LessonsPage from "./components/LessonsPage";
import Subscription from './components/Subscription';
import HomePage from './components/HomePage';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import CategoriesPage from './components/CategoriesPage';
import CancelSubscription from './components/CancelSubscription';
import Users from './components/Users'; // Import the Users component
import PrivateRoute from './components/PrivateRoute';
import AdminPanelLessonsEditor from "./components/AdminPanelLessonsEditor";
import AdminPanelEditLesson from "./components/AdminPanelEditLesson";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
           <Route path="/subscription-tiers" element={<Subscribe />} /> 
          <Route element={<PrivateRoute />}>
            <Route path="/subscribe" element={<Subscription />} />
            <Route path="/lessons" element={<LessonsPage />} />
            <Route path="/lessons/:lessonId" element={<LessonsPage />} />
            <Route path="/categories" element={<CategoriesPage />} />
            <Route path="/subscribe/cancel" element={<CancelSubscription />} />
            <Route path="/dashboard" element={<HomePage />} />
            {/*<Route path="/users" element={<Users />} /> /!* Add Users route *!/*/}
            <Route path="/admin/lessons" element={<AdminPanelLessonsEditor />} /> {/* Add Users route */}
            <Route path="/admin/lessons/:lessonId" element={<AdminPanelEditLesson />} /> {/* Add Users route */}
            <Route path="/admin/users" element={<Users />} /> {/* Add Users route */}
          </Route>
          <Route path="/" element={<Login />} /> {/* Default route */}
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
