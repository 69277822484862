import React, {useState, useEffect, useContext} from 'react';
import axios from '../utils/service';
import Spinner from './Spinner';
import styles from './LessonsPage.module.css';

import AuthContext from "../context/AuthContext"; // Reuse the same styles

function CategoriesPage() {
  const [categories, setCategories] = useState({});
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [previousFile, setPreviousFile] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const { user } = useContext(AuthContext);
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };
  useEffect(() => {
    axios.get(`/lessons/categories`, config)
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const handleFileSelect = (file) => {
    setPreviousFile(selectedFile);
    setSelectedFile(file);
    setSearchResults(null);
    axios.get(`/lessons/${file}`, config)
      .then(response => setFileContent(response.data))
      .catch(error => console.error('Error fetching file content:', error));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true
    axios.get(`/lessons/search?query=${searchQuery}`, config)
      .then(response => {
        setSearchResults(response.data);
        setPreviousFile(selectedFile);
        setSelectedFile(null);
      })
      .catch(error => console.error('Error searching:', error))
      .finally(() => setLoading(false)); // Set loading to false after search completes
  };

  const handleGoBack = () => {
    if (previousFile) {
      handleFileSelect(previousFile);
    }
  };

  const toggleCategory = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const [isResizing, setIsResizing] = useState(false);
  const [column1Width, setColumn1Width] = useState('120px');

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const container = document.getElementById(styles.mainContent);
      const containerOffsetLeft = container.offsetLeft;
      const containerWidth = container.offsetWidth;
      const mouseX = e.clientX - containerOffsetLeft;

      const newColumn1Width = (mouseX / containerWidth) * 100;
      if (newColumn1Width > 10 && newColumn1Width < 90) {
        setColumn1Width(`${newColumn1Width}%`);
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className={styles.lessonsPage}>
      <div className={styles.mainContent} id={styles.mainContent}>
        <div className={styles.sidebar}  style={{ width: column1Width }}>
          <form onSubmit={handleSearch} className={styles.searchForm}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search lessons..."
              className={styles.searchInput}
            />
            <button type="submit" className={styles.searchButton}>Search</button>
          </form>
          <h2>Categories</h2>
          {Object.keys(categories).map(category => (
            <div key={category} className={styles.category}>
              <div className={styles.categoryHeader} onClick={() => toggleCategory(category)}>
                <h3><span className={styles.arrow}>{expandedCategory === category ? '▲' : '▼'}</span> {category}</h3>
              </div>
              {expandedCategory === category && (
                <ul className={styles.lessonList}>
                  {categories[category].map(file => (
                    <li key={file} onClick={() => handleFileSelect(file)}>
                      {file.replace(".html", "")}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
        <div
            id="divider"
            className={styles.scrollDivider}
            onMouseDown={handleMouseDown}
        />
        <div className={styles.content} onContextMenu={handleContextMenu}>
        {loading ? (
          <Spinner />
        ) : searchResults ? (
          <div>
            <h2>Search Results</h2>
            {searchResults.length === 0 ? (
              <p>No results found.</p>
            ) : (
              searchResults.map((result, index) => (
                <div key={index} className={styles.searchResult}>
                  <h3>{result.file}</h3>
                  <button onClick={() => handleFileSelect(result.file)}>
                    View Lesson
                  </button>
                </div>
              ))
            )}
          </div>
        ) : selectedFile ? (
          <div dangerouslySetInnerHTML={{ __html: fileContent }} />
        ) : (
          <p>Select a lesson from the sidebar or search for content.</p>
        )}
      </div>
      </div>
    </div>
  );
}

export default CategoriesPage;
