// CheckoutForm.js
import React, {useContext, useState} from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from '../utils/service';
import AuthContext from "../context/AuthContext";

function CheckoutForm() {
    const stripe = useStripe(); // Hook to access the Stripe object
    const elements = useElements(); // Hook to access the Elements object
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { user, refresh_user } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        if (!stripe || !elements) {
            console.error("Stripe.js hasn't loaded yet.");
            return;
        }
        const { currentSubscription } = await axios.get(`/user/subscription`, config);

        if (currentSubscription) {
            console.error("User already have subscription.");
            return;
        }

        // Get the CardElement instance
        const cardElement = elements.getElement(CardElement);

        // Create a payment method using the card details
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(error.message);
        } else {
            setError(null);
            setSuccess(true);
            console.log('PaymentMethod created:', paymentMethod);
            // Send paymentMethod.id to your server to create a subscription
            // For example:
            await axios.post(`/user/subscription`, {
                paymentMethodId: paymentMethod.id
            }, config);
            await refresh_user();
        }
    };

    return (
        <form onSubmit={handleSubmit} style={styles.form}>
            <label>
                Card Details
                <CardElement />
            </label>
            <button type="submit" disabled={!stripe} style={styles.button}>
                Pay
            </button>
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {success && <div style={{ color: 'green' }}>Payment successful! Please login to apply changes!</div>}
        </form>
    );
}

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto 0',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    blurb: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#555',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    button: {
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        marginBottom: '10px',
    },
    link: {
        textAlign: 'center',
        marginTop: '10px',
    },
    userInfo: {
        marginTop: '20px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    },
};

export default CheckoutForm;
