import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Subscribe.css'; // Importing the CSS file for styling

const Subscribe = () => {
  return (
    <div className="subscribe-container">
      <h2 className="subscribe-heading">Subscription Tiers</h2>
      {/* New subheading added below the main heading */}
      <p className="subscribe-subheading">Choose a Subscription Package</p>
      <div className="subscribe-tiers">
        {/* Leftmost Box */}
        <Link to="/" className="subscribe-box-link"> {/* Link for the entire box */}
          <div className="subscribe-box">
            <h3>$249.99 / month</h3>
            <p className="subheading">*$199.99 w/ annual subscription</p>
            <ul>
              <li>All course access</li>
              <li>Discord support</li>
            </ul>
          </div>
        </Link>

        {/* Middle Box */}
        <Link to="/" className="subscribe-box-link"> {/* Link for the entire box */}
          <div className="subscribe-box">
            <h3>$299.99 / month</h3>
            <p className="subheading">*$239.99 w/ annual subscription</p>
            <ul>
              <li>All course access</li>
              <li>Discord support</li>
              <li>Check Decide drill library</li>
            </ul>
          </div>
        </Link>

        {/* Rightmost Box */}
        <Link to="/" className="subscribe-box-link"> {/* Link for the entire box */}
          <div className="subscribe-box">
            <h3>$399.99 / month</h3>
            <p className="subheading">*$319.99 w/ annual subscription</p>
            <ul>
              <li>All course access</li>
              <li>Discord support</li>
              <li>1 on 1 coaching w/ Nate</li>
            </ul>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Subscribe;
