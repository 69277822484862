import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import axios from "../utils/service";
import Spinner from "./Spinner";
import dashStyle from './Dashboard.module.css';
import Dashboard from "./Dashboard";
const HomePage = () => {
  const { user } = useContext(AuthContext);

  return (
    <div style={styles.container}>
      {user?.role === "admin" && (
          <div>Welcome Admin!</div>
      )}
      {user?.role !== "admin" && (
          <Dashboard />
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  userInfo: {
    marginTop: '20px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
  },
  colContainer: {
    display: 'flex'
  },
  col: {
    flex: 1,
    padding: '10px',
    border: '0px solid #ccc',
    boxSizing: 'border-box'
  }
};

export default HomePage;
