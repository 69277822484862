import React, { createContext, useState, useEffect } from 'react';
import axios from '../utils/service';
import {baseURL} from "../utils/consts";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (emailOrUsername, password) => {
    console.log(`baseURL: ${baseURL} ${process.env.NODE_ENV}`);
    const { data } = await axios.post(`/auth/login`, { emailOrUsername, password });
    localStorage.setItem('token', data.token);
    setUser(data);
  };

  const refresh_user = async () => {
    const token = user?.token;
    if(token) {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const {data} = await axios.get(`/auth/me`, config);
      setUser(data);
    }
  };

  const logout = async () => {
    const { data } = await axios.post(`/auth/logout`);
    localStorage.removeItem('token');
    setUser(null);
  };

  const register = async (username, email, password) => {
    const { data } = await axios.post(`/auth/register`, { username, email, password });
    localStorage.setItem('token', data.token);
    setUser(data);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, register, refresh_user }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
