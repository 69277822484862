import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';


const CancelSubscription = () => {
    const [message, setMessage] = useState('');
    const { user } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`,
            },
        };
        try {
            const response = await axios.delete(`/user/subscription`, config);
            console.log(response);
            if (response) {
                user.role = 'user';
                styles.success.color = 'green';
                setMessage('Your subscription was canceled.');
            } else {
                styles.success.color = 'red';
                setMessage('Failed to cancel subscription, try again!');
            }
        } catch (error) {
            console.error(error);
            setMessage('Error occurred. Please try again later.');
        }
    };
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Cancel Subscription</h1>
            <p>Are you sure you wish to cancel?</p>
            <form onSubmit={handleSubmit} style={styles.form}>
                <button type="submit" style={styles.button}>Yes, cancel my subscription</button>
                <Link to="/dashboard">No, take my back!</Link>
            </form>
            {message &&
                (<div>
                    <p style={styles.success}>{message}</p>
                    <p>Go back to <Link to="/dashboard">dashboard</Link>.</p>
                </div>)}
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto 0',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    blurb: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#555',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    button: {
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        marginBottom: '10px',
    },
    link: {
        textAlign: 'center',
        marginTop: '10px',
    },
};

export default CancelSubscription;
