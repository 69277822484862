import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from '../utils/service';


const ForgotPassword = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/user/forgot-password`, { email });
      if (response.status == 200) {
        setMessage('Reset link sent to email.');
      } else {
        setMessage('Failed to send reset link.');
      }
    } catch (error) {
      setMessage('Error occurred. Please try again later.');
    }
  };
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Forgot Password</h1>
      <p>Reset password for your account.</p>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            style={styles.input}
            required
        />
        <button type="submit" style={styles.button}>Reset password</button>
      </form>
      {message && <p style={styles.success}>{message}</p>}
      <p>Go back to <Link to="/login">login</Link>.</p>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '500px',
    margin: '50px auto 0',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  blurb: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#555',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  button: {
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
  success: {
    color: 'green',
    marginBottom: '10px',
  },
  link: {
    textAlign: 'center',
    marginTop: '10px',
  },
};

export default ForgotPassword;
