import axios from 'axios';
import { baseURL } from './consts';


console.log("baseURL: " + baseURL);
const instance = axios.create({
    baseURL: baseURL, // Update this if your backend is running on a different port
});

export default instance;
