import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../utils/service';


function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`/user/reset-password/${token}`, { password });
            if (response) {
                setMessage('Password has been reset successfully');
            } else {
                setMessage('Failed to reset password');
            }
        } catch (error) {
            setMessage('Error occurred. Please try again later.');
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Reset Password</h1>
            <form onSubmit={handleSubmit} style={styles.form}>
                <label>
                    New Password:
                    <input
                        style={styles.input}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Confirm Password:
                    <input
                        style={styles.input}
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </label>
                <button type="submit" style={styles.button}>Reset Password</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
}

const styles = {
    container: {
        maxWidth: '500px',
        margin: '50px auto 0',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '10px',
    },
    blurb: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#555',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    button: {
        padding: '10px',
        margin: '10px 0',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginBottom: '10px',
    },
    success: {
        color: 'green',
        marginBottom: '10px',
    },
    link: {
        textAlign: 'center',
        marginTop: '10px',
    },
};

export default ResetPassword;
