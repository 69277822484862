import React, {useEffect, useState, useContext} from 'react';
import axios from '../utils/service';
import AuthContext from '../context/AuthContext';
import Modal from 'react-modal';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
import Spinner from "./Spinner";


const Users = () => {
    const {user} = useContext(AuthContext);
    const [users, setUsers] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editFormData, setEditFormData] = useState({email: '', password: '', role: ''});
    const [addFormData, setAddFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 'user'
    });
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                };
                const {data} = await axios.get(`/admin/users`, config);
                setUsers(data)
            } catch (error) {
                console.error('Failed to fetch users:', error);
            }
        };

        if (user && user.role === 'admin') {
            fetchUsers();
        }
    }, [user]);

    const openDeleteModal = (user) => {
        setSelectedUser(user);
        setModalIsOpen(true);
    };

    const closeDeleteModal = () => {
        setModalIsOpen(false);
        setSelectedUser(null);
    };

    const openEditModal = (user) => {
        setSelectedUser(user);
        setEditFormData({email: user.email, password: '********', role: user.role});
        setEditModalIsOpen(true);
    };

    const closeEditModal = () => {
        setEditModalIsOpen(false);
        setSelectedUser(null);
    };

    const openAddModal = () => {
        setAddFormData({username: '', email: '', password: generatePassword(), confirmPassword: '', role: 'user'});
        setAddModalIsOpen(true);
    };

    const closeAddModal = () => {
        setAddModalIsOpen(false);
    };

    const handleEditChange = (e) => {
        const {name, value} = e.target;
        setEditFormData({...editFormData, [name]: value});
    };

    const handleAddChange = (e) => {
        const {name, value} = e.target;
        setAddFormData({...addFormData, [name]: value});
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            };
            const {email, password, role} = editFormData;
            const updateData = {email, role};
            if (password !== '********') {
                updateData.password = password;
            }
            await axios.put(`/admin/user/${selectedUser._id}`, updateData, config);
            setUsers(users.map((u) => (u._id === selectedUser._id ? {...u, email, role} : u)));
            closeEditModal();
        } catch (error) {
            console.error('Failed to edit user:', error);
        }
    };

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        if (addFormData.password !== addFormData.confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            };
            const {username, email, password, role} = addFormData;
            await axios.post(`/admin/user`, {username, email, password, role}, config);
            setUsers([...users, {username, email, role}]);
            closeAddModal();
        } catch (error) {
            console.error('Failed to add user:', error);
        }
    };

    const deleteUser = async () => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            };
            await axios.delete(`/admin/user/${selectedUser._id}`, config);
            setUsers(users.filter((u) => u._id !== selectedUser._id));
            closeDeleteModal();
        } catch (error) {
            console.error('Failed to delete user:', error);
        }
    };

    const generatePassword = () => {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";
        for (let i = 0; i < 12; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };

    return (
        <div style={styles.container}>
            <h1>Admin Panel</h1>
            <h2>Users</h2>
            <button onClick={openAddModal} style={styles.addButton}>Add User</button>
            {!users ? (
                <Spinner/>
            ) : (
                <>
                    <table style={styles.table}>
                        <thead>
                        <tr>
                            <th style={styles.th}>Username</th>
                            <th style={styles.th}>Email</th>
                            <th style={styles.th}>Role</th>
                            <th style={styles.th}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user, index) => (
                            <tr key={user._id} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
                                <td style={styles.td}>{user.username}</td>
                                <td style={styles.td}>{user.email}</td>
                                <td style={styles.td}>{user.role}</td>
                                <td style={styles.td}>
                                    <button style={styles.editButton} onClick={() => openEditModal(user)}>Edit</button>
                                    <button style={styles.deleteButton} onClick={() => openDeleteModal(user)}>Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    {/* Delete Confirmation Modal */}
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeDeleteModal}
                        style={styles.modal}
                        contentLabel="Confirmation Modal"
                    >
                        <h2>Are you sure?</h2>
                        <p>Do you really want to delete this user? This process cannot be undone.</p>
                        <button style={styles.confirmButton} onClick={deleteUser}>Yes</button>
                        <button style={styles.cancelButton} onClick={closeDeleteModal}>No</button>
                    </Modal>

                    {/* Edit User Modal */}
                    <Modal
                        isOpen={editModalIsOpen}
                        onRequestClose={closeEditModal}
                        style={styles.modal}
                        contentLabel="Edit User Modal"
                    >
                        <h2>Edit User</h2>
                        <form onSubmit={handleEditSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email:</label>
                                <input style={styles.input} type="email" name="email" value={editFormData.email}
                                       onChange={handleEditChange} required/>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Role:</label>
                                <select style={styles.input} name="role" value={editFormData.role}
                                        onChange={handleEditChange} required>
                                    <option key="user" value="user">User</option>
                                    <option key="admin" value="admin">Admin</option>
                                </select>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Password:</label>
                                <input style={styles.input} type={showPassword ? "text" : "password"} name="password"
                                       value={editFormData.password} onChange={handleEditChange}
                                       placeholder="********"/>
                                <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash/> : <FaEye/>}
            </span>
                            </div>
                            <button type="submit" style={styles.confirmButton}>Save</button>
                            <button type="button" style={styles.cancelButton} onClick={closeEditModal}>Cancel</button>
                        </form>
                    </Modal>

                    {/* Add User Modal */}
                    <Modal
                        isOpen={addModalIsOpen}
                        onRequestClose={closeAddModal}
                        style={styles.modal}
                        contentLabel="Add User Modal"
                    >
                        <h2>Add User</h2>
                        <form onSubmit={handleAddSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Username:</label>
                                <input style={styles.input} type="text" name="username" value={addFormData.username}
                                       onChange={handleAddChange} required/>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email:</label>
                                <input style={styles.input} type="email" name="email" value={addFormData.email}
                                       onChange={handleAddChange} required/>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Role:</label>
                                <select style={styles.input} name="role" value={addFormData.role}
                                        onChange={handleAddChange} required>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Password:</label>
                                <input style={styles.input} type={showPassword ? "text" : "password"} name="password"
                                       value={addFormData.password} onChange={handleAddChange} required/>
                                <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash/> : <FaEye/>}
            </span>
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Confirm Password:</label>
                                <input style={styles.input} type={showPassword ? "text" : "password"}
                                       name="confirmPassword" value={addFormData.confirmPassword}
                                       onChange={handleAddChange} required/>
                                <span onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <FaEyeSlash/> : <FaEye/>}
            </span>
                            </div>
                            <button type="submit" style={styles.confirmButton}>Add User</button>
                            <button type="button" style={styles.cancelButton} onClick={closeAddModal}>Cancel</button>
                        </form>
                    </Modal>
                </>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
    },
    addButton: {
        marginBottom: '20px',
        padding: '10px 15px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '3px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
    },
    th: {
        borderBottom: '2px solid #ddd',
        padding: '10px',
        textAlign: 'left',
        backgroundColor: '#333',
        color: '#fff',
    },
    td: {
        borderBottom: '1px solid #ddd',
        padding: '10px',
    },
    evenRow: {
        backgroundColor: '#f9f9f9',
    },
    oddRow: {
        backgroundColor: '#ffffff',
    },
    editButton: {
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        padding: '5px 10px',
        cursor: 'pointer',
        borderRadius: '3px',
        marginRight: '5px',
    },
    deleteButton: {
        backgroundColor: '#ff4d4d',
        color: '#fff',
        border: 'none',
        padding: '5px 10px',
        cursor: 'pointer',
        borderRadius: '3px',
    },
    modal: {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            textAlign: 'center',
        },
    },
    confirmButton: {
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        margin: '10px',
        cursor: 'pointer',
        borderRadius: '3px',
    },
    cancelButton: {
        backgroundColor: '#6c757d',
        color: '#fff',
        border: 'none',
        padding: '10px 20px',
        margin: '10px',
        cursor: 'pointer',
        borderRadius: '3px',
    },
    formGroup: {
        marginBottom: '15px',
        textAlign: 'left',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
    },
    input: {
        width: '100%',
        padding: '8px',
        boxSizing: 'border-box',
    },
};

export default Users;
