import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../utils/service';
import Spinner from './Spinner';
import styles from './LessonsPage.module.css';
import AuthContext from "../context/AuthContext";

function LessonsPage() {
  const navigate = useNavigate();
  const { lessonId } = useParams();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [previousFile, setPreviousFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle for mobile

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  useEffect(() => {
    if (lessonId) {
      handleFileSelect(`${lessonId}.html`);
    }
    axios.get(`/lessons`, config)
      .then(response => setFiles(response.data))
      .catch(error => console.error('Error fetching files:', error));
  }, [lessonId]);

  const adjustImagePaths = (content, filename) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    doc.querySelectorAll('li').forEach(li => {
      li.style.listStyleType = 'disc';
      const checkbox = li.querySelector('input[type="checkbox"]');
      if (checkbox) checkbox.remove();
    });
    return doc.body.innerHTML;
  };

  const handleFileSelect = (file) => {
    setPreviousFile(selectedFile);
    setSelectedFile(file);
    setSearchResults(null);
    setLoading(true);
    const encodedLessonName = encodeURIComponent(file.replace('.html', ''));
    navigate(`/lessons/${encodedLessonName}`);
    axios.get(`/lessons/${file}`, config)
      .then(response => {
        const adjustedContent = adjustImagePaths(response.data, file);
        setFileContent(adjustedContent);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching file content:', error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.get(`/lessons/search?query=${searchQuery}`, config)
      .then(response => {
        setSearchResults(response.data);
        setPreviousFile(selectedFile);
        setSelectedFile(null);
      })
      .catch(error => console.error('Error searching:', error))
      .finally(() => setLoading(false));
  };

  const handleGoBack = () => {
    if (previousFile) {
      handleFileSelect(previousFile);
    }
  };

  return (
    <div className={styles.lessonsPage}>
      {/* Sidebar Toggle Button for Mobile Only */}
      <button
        className={styles.sidebarToggle}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? '←' : '→'}
      </button>

      <div className={styles.mainContent}>
        <div className={`${styles.sidebar} ${isSidebarOpen ? styles.sidebarOpen : ''}`}>
          <form onSubmit={handleSearch} className={styles.searchForm}>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search lessons..."
              className={styles.searchInput}
            />
            <button type="submit" className={styles.searchButton}>Search</button>
          </form>
          <h2>Lessons</h2>
          <ul className={styles.lessonList}>
            {files.map(file => (
              <React.Fragment key={file}>
                <li onClick={() => handleFileSelect(file)}>
                  {file.replace('.html', '')}
                </li>
                <hr className={styles.divider} />
              </React.Fragment>
            ))}
          </ul>
        </div>

        <div className={styles.content}>
          {previousFile && (
            <button onClick={handleGoBack} className={styles.goBackButton}>
              Go back to {previousFile.replace('.html', '')}
            </button>
          )}
          {loading ? (
            <Spinner />
          ) : searchResults ? (
            <div className={styles.readerContent}>
              <h2>Search Results</h2>
              {searchResults.length === 0 ? (
                <p>No results found.</p>
              ) : (
                searchResults.map((result, index) => (
                  <div key={index} className={styles.searchResult}>
                    <h3>{result.file.replace('.html', '')}</h3>
                    <button onClick={() => handleFileSelect(result.file)}>
                      View Lesson
                    </button>
                  </div>
                ))
              )}
            </div>
          ) : selectedFile ? (
            <div className={styles.readerContent} dangerouslySetInnerHTML={{ __html: fileContent }} />
          ) : (
            <p>Select a lesson from the sidebar or search for content.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default LessonsPage;
